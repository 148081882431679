import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Story from "./components/Story";
import Music from "./components/Music";
import NotFound from "./components/NotFound";
import './App.css';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/story" element={<Story />} />
          <Route path="/music" element={<Music />} />
          <Route path="*" element={<NotFound />} /> {/* Wildcard Route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
