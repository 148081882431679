import React from "react";
import { Link } from "react-router-dom";

function Story() {
  return (
    <div className="container story-container">
      <header className="header">
        <Link to="/" className="title">Spaceships and Graffiti</Link>
      </header>
      <div className="content story-content">
        <p>
          This is the story of Spaceships and Graffiti, a tale where cosmic adventure 
          meets street art in a world beyond imagination...
        </p>
        <Link to="/music" className="link">Explore The Music</Link>
      </div>
      <footer className="container footer-container">
        <p>© 2024 Hip Media Entertainment</p>
      </footer>
    </div>
  );
}

export default Story;
