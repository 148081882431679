import React from "react";
import songs from "../data/songs"; // Adjust the path if needed

function Music() {
  const introText = `
    It's all about the music… Right?<br>
    The artist/writer Ezra Browne has compiled a playlist that is featured within the footnotes on the pages as you read this graphic novel.<br>
    So scan the QR code below and take the intended journey that the artist felt when he created these pages.<br>
    Peace… Knowledge, Knowledge
  `;

  // Split the songs into two columns
  const midpoint = Math.ceil(songs.length / 2);
  const column1 = songs.slice(0, midpoint);
  const column2 = songs.slice(midpoint);

  return (
    <div className="container music-container">
      <header className="pageheader">
        <h1 className="pagetitle">Spaceships & Graffiti</h1>
        <h1 className="pagetitle">The Music</h1>
      </header>
      <div className="intro-text" dangerouslySetInnerHTML={{ __html: introText }}></div>
      <div className="embeds">
        <div className="embed-item">
          <iframe
            className="spotify-embed"
            src="https://open.spotify.com/embed/playlist/51Rh7mxGspxKuZRYPUlO8j?utm_source=generator"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Spotify Playlist"
          >
          </iframe>
        </div>
        <div className="embed-item">
          <iframe
            className="itunes-embed"
            src="https://embed.music.apple.com/us/playlist/spaceships-graffiti/pl.u-KVX2qqVC4pxXL"
            allow="autoplay *; encrypted-media *; fullscreen *"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            title="Apple Music Playlist"
          ></iframe>
        </div>
      </div>
      <div className="qr-codes">
        <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
          <img src="/Spotify.svg" alt="Spotify QR Code" className="qr-image" />
        </a>
        <a href="https://apple.com" target="_blank" rel="noopener noreferrer">
          <img src="/Apple.svg" alt="Apple QR Code" className="qr-image" />
        </a>
      </div>
      <div className="song-list">
      <div className="song-column">
  {column1.map((song, index) => (
    <div key={index} className="song-item">
      <span className="song-number">{index + 1}.</span>
      <span className="song-title"><strong>{song.title}</strong> - {song.artist}</span>
    </div>
  ))}
</div>
<div className="song-column">
  {column2.map((song, index) => (
    <div key={midpoint + index} className="song-item">
      <span className="song-number">{midpoint + index + 1}.</span>
      <span className="song-title"><strong>{song.title}</strong> - {song.artist}</span>
    </div>
  ))}
</div>
      </div>
      <footer className="footer">
        <img src="/footer-logo.jpg" alt="Logo" />
        <p>© 2024 Hip Media Entertainment</p>
      </footer>
    </div>
  );
}

export default Music;
