import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="container home-container">
      <header className="header">
        <h1 className="title">Spaceships and Graffiti</h1>
      </header>
      <div className="links">
        <Link to="/story" className="link">The Story</Link>
        <Link to="/music" className="link">The Music</Link>
      </div>
      <footer className="footer">
        <img src="/footer-logo.jpg" alt="Hip Media Entertainment" />
        <p>© 2024 Hip Media Entertainment</p>
      </footer>
    </div>
  );
}

export default Home;
