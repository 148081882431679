import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container home-container">
      <div>
        <h1 className="title">404: Page Not Found</h1>
        <p className="content">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link to="/" className="link">
          Go Back Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
